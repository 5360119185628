import axios from 'axios'
import { getJwtString } from '../../components/Auth'
import API from '../../components/API'

axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * Retorna los registros de la tabla permissions
 * @returns {Promise}
 */
export function getPermissions () {
  return axios.get(API('permissions'), {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * Retorna los registros de la tabla permission_role
 * @returns {Promise}
 */
export function getPermissionRole () {
  return axios.get(API('permission-role'), {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * @param {Object} values - Todos los permission_role a guardar en DB
 * @returns {Promise}
 */
export function putPermissionRole (values) {
  return axios.put(API('permission-role'), { values }, {
    headers: {
      Authorization: getJwtString()
    }
  })
}
